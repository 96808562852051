import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: flex;
  background-color: #333a45; 
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1vh 6vh;
  border-bottom: 0.5px solid #f4f4f4;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 1vh;
    align-items: center;
    padding: 1vh 2vh;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 1vh;
    justify-content: center;
  }
`;

const LogoImg = styled.img`
  margin-left: 1%;
  height: 5.6vh;
  width: auto;

  &:hover {
    opacity: 0.65;
  }
`;

const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;

  gap: 20px;
  margin-left: 20px;
  text-decoration: none;

  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 1vh;
    margin-left: 0; 
  }
`;

const NavLink = styled.a`
  font-size: 1em;
  color: #93f514;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px;

  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 1vh;
  }
`;

const SocialImg = styled.img`
  height: 6.3vh;
  width: auto;

  &:hover {
    opacity: 0.8;
  }
`;

const Header = () => {
  return (
    <HeaderContainer>
      <LogoContainer>
        <LogoImg src="./images/logo.png" alt="Nice Logo" />
      </LogoContainer>
      <NavContainer>
        <NavLink class="nav-link-header" href="#what-is-nice">What is Nice?</NavLink>
        <NavLink class="nav-link-header" href="#tokenomics">Tokenomics</NavLink>
      </NavContainer>
      <SocialContainer>
        <a href="https://t.me/NiceTokenOnBase" target="_blank"> <SocialImg src="./images/telegram.png" alt="Telegram" /></a>
        <a href="https://x.com/Nicetokenbase" target="_blank"><SocialImg src="./images/x.png" alt="X - Twitter" /></a>
        <a href="https://warpcast.com/nicetoken" target="_blank"><SocialImg src="./images/warpcast.png" alt="Warpcast" /></a>
      </SocialContainer>
    </HeaderContainer>
  );
};

export default Header;
