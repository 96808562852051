import React, { useEffect, useRef } from 'react';

const TokenomicsChart = () => {
    return (
        <section>
            <a id="tokenomics"></a>
            <div className="chart-container animate__animated animate__fadeInLeft">
                <h2 className="chart-title">$NICE Token Distribution</h2>
                <div class="chart-image-container row d-flex justify-content-center">
                    <div class="d-flex justify-content-center p-0 m-0"><img class="chart-image" src="/images/nice-charts.png"></img></div>
                </div>
            </div>
        </section>
            );
};

            export default TokenomicsChart;
