import React from 'react';
import styled from 'styled-components';

const AboutSection = styled.section`
  background-color: #282c34;
  padding: 20px;
  padding-top: 29.5vh;
  padding-bottom: 29.5vh;
  text-align: center;
  @media (max-width: 768px) {
    padding-top: 8vh;
    padding-bottom: 8vh;
  }
`;

const Title = styled.h1`
font-family: 'Montserrat', sans-serif;
  font-size: 2.5em;
  margin-bottom: 8vh;
  color: #93f514;
  @media(max-width: 1400px) {
    font-size: 2em;
  }
  @media (max-width: 768px) {
    font-size: 1.85em;
  }
  
`;

const ProductList = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
`;

const ProductItem = styled.div`
  flex: 1;
  min-width: 200px;
  max-width: 35%;
  background-color: #333a45; 
  padding: 50px;
  margin: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Icon = styled.div`
  margin-bottom: 20px;

  img {
    max-width: 4vw;
  }
  @media (max-width: 768px) {
    img {
        max-width: 16vw;
    }
  }
`;


const ProductDescription = styled.p`
  font-size: 1.4em;
  font-family: 'Montserrat', sans-serif;
  color: #93f514;
  line-height: 1.6;
  @media(max-width: 1400px) {
    font-size: 1.15em;
  }
  @media (max-width: 768px) {
    font-size: 1.05em;
  }
`;

const About = () => {
    return (
        <section>
            <a id="what-is-nice"></a>

            <AboutSection>
                <Title>What is $NICE?</Title>
                <ProductList>
                    <ProductItem>
                        <Icon>
                            <img src="/images/pepe-champagne.png" alt="Icon" />
                        </Icon>
                        <ProductDescription>
                            $NICE is the coin for everything nice, nice memes, nice art, nice cats, nice thoughts, nice food, nice moments and more!
                        </ProductDescription>
                    </ProductItem>
                    <ProductItem>
                        <Icon>
                        <img src="/images/pepe-phone.png" alt="Icon" />
                        </Icon>
                        <ProductDescription>
                            $NICE is supported by the /nice channel in Farcaster, gaining 100+ members daily is positioned to be one of the biggest channels in the ecosystem, a home for everything nice, /nice channel is forever.
                        </ProductDescription>
                    </ProductItem>
                    <ProductItem>
                        <Icon>
                        <img src="/images/pepe-troll.png" alt="Icon" />
                        </Icon>
                        <ProductDescription>
                            $NICE can be a utopia, a state of mind, a community, a meme, a cult, anything nice. We can have something $NICE and this is only the beginning!
                        </ProductDescription>
                    </ProductItem>
                </ProductList>
            </AboutSection>
        </section>
    );
};

export default About;
